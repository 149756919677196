<template>
  <div
    style="display: inline-block; margin-left: 5px; margin-right: 5px"
    class="icon-btn"
  >
    <template v-if="$store.state.btn_mode">
      <el-tooltip effect="dark" :content="content" placement="top">
        <el-button
          :type="btn_type"
          size="mini"
          :class="btn_class"
          @click="$emit('click')"
          ><i :class="['iconfont', icon]"></i
        ></el-button>
      </el-tooltip>
    </template>
    <el-button
      v-else
      :type="btn_type"
      size="mini"
      :class="btn_class"
      @click="$emit('click')"
      >{{ content }}</el-button
    >
  </div>
</template>

<script>
export default {
  name: "ZcIconBtn",
  props: {
    content: {
      type: String,
      default: " ",
    },
    btn_type: {
      type: String,
      default: "primary",
    },
    btn_class: {
      default: "form_button",
    },
    icon: {
      type: String,
      default: "icon-shouye",
    },
  }
};
</script>