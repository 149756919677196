<template>
  <div
    id="crm-cus"
    v-cloak
    v-loading.fullscreen.lock="loading"
    @click="clickTarget($event)"
  >
    <h1 class="cus-tit" v-if="page == 0">{{ $t("CrmCus.taskTags") }}</h1>
    <h1 class="cus-tit" v-if="page == 1">{{ $t("CrmCus.projectTasks") }}</h1>
    <h1 class="cus-tit" v-if="page == 2">{{ $t("CrmCus.customLib") }}</h1>
    <h1 class="tit-cus" v-if="page == 2">
      <span
        :class="[showPage === 0 ? 'active-nav' : '', 'max-box-tit']"
        @click="getCrmCompany(0)"
        v-if="plugRightShow('CrmContact@getContactLabel')"
      >
        {{ label[page] }}{{ $t("contactList.label") }}
      </span>
      <span
        :class="[showPage === 1 ? 'active-nav' : '', 'write-tit']"
        @click="getCrmCompany(1)"
        v-if="plugRightShow('CrmContact@getContactField')"
      >
        {{ $t("CrmCus.companyRecord") }}
      </span>
      <span
        :class="[showPage === 2 ? 'active-nav' : '', 'write-tit']"
        @click="getCrmCompany(2)"
        v-if="plugRightShow('CrmContact@getContactStar')"
      >
        {{ $t("contactList.star") }}
      </span>
      <span
        :class="[showPage === 3 ? 'active-nav' : '', 'write-tit']"
        @click="getCrmCompany(3)"
        v-if="plugRightShow('CrmContact@getContactGroup')"
      >
        {{ $t("CrmCus.contactGroup") }}
      </span>
      <span
        :class="[showPage === 4 ? 'active-nav' : '', 'write-tit']"
        @click="getCrmCompany(4)"
        v-if="plugRightShow('CrmContact@getContactRegion')"
      >
        {{ $t("CrmCus.contactRegion") }}
      </span>
    </h1>
    <h1 class="tit-cus" v-else>
      {{ $t("CrmCus.editTag") }}
    </h1>
    <div
      v-if="
        (plugRightShow('CrmTask@getTaskLabel') ||
          plugRightShow('CrmTask@getProjectLabel') ||
          plugRightShow('CrmContact@getContactLabel')) &&
        showPage == 0
      "
    >
      <div class="search-mail">
        <div @click.stop>
          <!-- <el-input size="mini" v-model.stop="key" class="search-input" -->
          <el-input
            size="mini"
            v-model="key"
            class="search-input"
            maxlength="20"
            show-word-limit
            :placeholder="$t('CrmCus.text')"
            clearable
            stop
          ></el-input>
        </div>
        <zc-icon-btn
          :content="$t('save')"
          v-if="showCancel"
          btn_type="warning"
          icon="icon-baocun"
          @click.native.stop="confirm"
        ></zc-icon-btn>

        <zc-icon-btn
          :content="$t('projectManagement.add')"
          v-else
          btn_type="primary"
          icon="icon-tianjia"
          @click.native.stop="confirm"
        ></zc-icon-btn>

        <!-- <el-button type="primary" @click.stop="confirm()" size="mini">
                    <div v-if="showCancel"><tags:lang>保存</tags:lang></div>
                    <div v-else><tags:lang>add</tags:lang></div>
                </el-button> -->
        <zc-icon-btn
          :content="$t('cancel')"
          v-if="showCancel"
          btn_type="primary"
          icon="icon-quxiao"
          class="cancel-btn"
          @click.native.stop="cancelLabel"
        ></zc-icon-btn>
        <!-- <el-button size="mini" class="cancel-btn" v-if="showCancel" @click="cancelLabel"><tags:lang>cancel</tags:lang></el-button> -->
      </div>
      <div class="color-box">
        <div
          v-for="(colorItem, colorIndex) in colorRadio"
          :class="{ 'active-color': checkRadio == colorIndex }"
          @click.stop="pickColor(colorIndex)"
          :key="colorIndex"
        ></div>
      </div>
      <div
        class="infinite-list-wrapper cus-item_container"
        v-show="showPage == 0"
      >
        <div class="tag-tit">
          <span>{{ $t("CrmCus.text1") }}</span>
          <span class="num">{{ tableData.length }} </span>
          <span class="unit">/{{ $t("CrmCus.unit") }}</span>
        </div>
        <draggable
          v-model="tableData"
          group="tags"
          @start="drag = true"
          @end="drag = false"
          class="draggable-item"
          v-bind="dragOptions"
        >
          <div
            v-for="(item, index) in tableData"
            :style="'background-color:' + item.value"
            :class="[
              showCancel && itemIndex - 1 == index ? 'border-tag' : '',
              'select-tags',
              'flex-center',
            ]"
            :key="index"
          >
            <div @click.stop="clickTags(index)">{{ item.name }}</div>
            <i class="el-icon-close del-icon" @click="delCompany(index)"></i>
          </div>
        </draggable>
      </div>
    </div>
    <div
      v-if="plugRightShow('CrmContact@getContactField') && showPage == 1"
      class="cus-item_container"
    >
      <div class="cus-form_tit mt32">
        {{ $t("CrmCus.companyRecord") }}
      </div>
      <draggable
        v-model="tableData"
        group="tags1"
        @start="drag = true"
        @end="drag = false"
        class="draggable-item"
        v-bind="dragOptions"
      >
        <div
          class="cus-form_item add-form_item w608"
          v-for="(it, index) in tableData"
          :key="index"
        >
          <el-popover
            placement="top-start"
            width="20"
            class="tip-popover"
            trigger="hover"
          >
            <span>{{ $t("CrmCus.text2") }}</span>
            <i slot="reference" class="iconfont icon-tishi tip-icon"></i>
          </el-popover>
          <el-input
            v-model="it['name']"
            class="form-input"
            size="mini"
          ></el-input>
          <i
            class="el-icon-delete red-text hand"
            @click="delCompany(index)"
          ></i>
        </div>
      </draggable>
      <div class="cus-form_item mt12 hand" @click="addCompany()">
        <i class="el-icon-circle-plus-outline yellow-text mr5"></i
        >{{ $t("CrmCus.addCompanyRecord") }}
      </div>
    </div>
    <div
      v-if="plugRightShow('CrmContact@getContactStar') && showPage == 2"
      class="cus-item_container"
    >
      <div class="cus-form_tit mt32">
        {{ $t("contactList.star") }}
      </div>
      <draggable
        v-model="tableData"
        group="tags2"
        @start="drag = true"
        @end="drag = false"
        class="draggable-item"
        v-bind="dragOptions"
      >
        <div
          class="cus-form_item add-form_item w608"
          v-for="(it, index) in tableData"
          :key="index"
        >
          <el-popover
            placement="top-start"
            width="20"
            class="tip-popover"
            trigger="hover"
          >
            <span>{{ $t("CrmCus.text2") }}</span>
            <i slot="reference" class="iconfont icon-tishi tip-icon"></i>
          </el-popover>
          <el-input
            v-model="it['name']"
            class="form-input"
            size="mini"
          ></el-input>
          <el-rate class="ml5"> </el-rate>
          <i
            class="el-icon-delete red-text ml10 hand"
            @click="delCompany(index)"
          ></i>
        </div>
      </draggable>
      <div class="cus-form_item mt16 hand" @click="addCompany()">
        <i class="el-icon-circle-plus-outline yellow-text mr5"> </i
        >{{ $t("CrmCus.addContactStar") }}
      </div>
    </div>
    <div
      v-if="plugRightShow('CrmContact@getContactGroup') && showPage == 3"
      class="cus-item_container"
    >
      <div class="cus-form_tit mt32">
        {{ $t("CrmCus.contactGroup") }}
      </div>
      <draggable
        v-model="tableData"
        group="tags2"
        @start="drag = true"
        @end="drag = false"
        class="draggable-item"
        v-bind="dragOptions"
      >
        <div
          class="cus-form_item add-form_item w608"
          v-for="(it, index) in tableData"
          :key="index"
        >
          <el-popover
            placement="top-start"
            width="20"
            class="tip-popover"
            trigger="hover"
          >
            <span>{{ $t("CrmCus.text2") }}</span>
            <i slot="reference" class="iconfont icon-tishi tip-icon"></i>
          </el-popover>
          <el-input
            v-model="it['name']"
            class="form-input"
            size="mini"
          ></el-input>
          <i
            class="el-icon-delete red-text mr10 ml10 hand"
            @click="delCompany(index)"
          ></i>
        </div>
      </draggable>
      <div class="cus-form_item mt16">
        <i
          class="el-icon-circle-plus-outline yellow-text mr5"
          @click="addCompany()"
        >
        </i
        >{{ $t("CrmCus.addContactGroup") }}
      </div>
    </div>
    <div
      v-if="plugRightShow('CrmContact@getContactRegion') && showPage == 4"
      class="add-w"
    >
      <div class="add-top_box mt32 flex-between">
        <span class="form-user">{{ $t("CrmCus.contactRegion") }}</span>
        <span>{{ $t("CrmCus.desc") }}</span>
      </div>
      <draggable
        v-model="tableData"
        group="tags2"
        @start="drag = true"
        @end="drag = false"
        class="add-draggable-item"
        v-bind="dragOptions"
      >
        <div
          class="add-form_item"
          v-for="(it, index) in tableData"
          :key="index"
        >
          <el-popover
            placement="top-start"
            width="20"
            class="tip-popover"
            trigger="hover"
          >
            <span>{{ $t("CrmCus.text2") }}</span>
            <i slot="reference" class="iconfont icon-tishi tip-icon"></i>
          </el-popover>
          <el-input
            v-model="it['name']"
            class="form-input mr20"
            size="mini"
          ></el-input>
          <el-input
            v-model="it['value']"
            class="form-input mr20"
            size="mini"
          ></el-input>
          <i
            class="el-icon-delete red-text mr5 hand"
            @click="delCompany(index)"
          ></i>
        </div>
      </draggable>
      <div class="cus-form_item mt16" @click="addCompany()">
        <i class="el-icon-circle-plus-outline yellow-text mr5"> </i
        >{{ $t("CrmCus.addContactRegion") }}
      </div>
    </div>
    <div class="btnBox">
      <!-- <el-button size="mini" @click="cancel" class="cancel-btn"><tags:lang>cancel</tags:lang></el-button> -->
      <!-- <el-button type="warning" size="mini" @click="submit" class="ml76"><tags:lang>保存</tags:lang></el-button> -->
      <zc-icon-btn
        :content="$t('save')"
        btn_type="warning"
        btn_class="ml76"
        icon="icon-baocun"
        @click="submit"
      ></zc-icon-btn>
    </div>
    <div class="no-save_btn flex-center hand" @click="cancel">
      <i class="el-icon-close"></i>
    </div>
    <el-dialog
      :title="$t('tips')"
      :visible.sync="dialogVisible"
      width="300px"
      center
      :before-close="cancelClose"
    >
      <span>{{ $t("CrmCus.text3") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goPageNotSave" size="mini">{{
          $t("cancel")
        }}</el-button>
        <el-button type="primary" @click="goPageSave" size="mini">{{
          $t("save")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ZcIconBtn from "@/components/ZcIconBtn.vue";
import draggable from "vuedraggable";
export default {
  props: ["page", "callback", "is_open"],
  components: {
    draggable,
    ZcIconBtn,
  },
  name: "CrmCus",
  data() {
    return {
      loading: false,
      dialogVisible: false,
      showPage: 0,
      isShowPage: 0,
      showCancel: false,
      // callBack: "{$callback}",
      key: "",
      itemIndex: "",
      // page: "{$page}", //  0任务 1项目 2客户
      //  0任务 1项目 2客户
      label: {
        0: this.$t("task"),
        1: this.$t("projects"),
        2: this.$t("contact"),
      },
      checkRadio: "",
      colorRadio: {
        1: "#0A84FF",
        2: "#FF575C",
        3: "#F7BE16",
        4: "#FF7315",
        5: "#0ACE0D",
      },
      colorRadioRe: {
        "#0A84FF": 1,
        "#FF575C": 2,
        "#F7BE16": 3,
        "#FF7315": 4,
        "#0ACE0D": 5,
      },
      tableData: [],
      tableDataBack: [],
      tableDataLabelBack: [],
      tagsGroup: [],
      tagsGroupBack: [],
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: this.showCancel ? true : false,
        ghostClass: "ghost",
      };
    },
  },
  inject: ["rightShow"],
  methods: {
    //取消显示提示
    cancelClose() {
      this.dialogVisible = false;
      this.isShowPage = this.showPage;
    },
    //跳转页面不保存
    goPageNotSave() {
      this.dialogVisible = false;
      if (this.isShowPage == 10) {
        //取消显示自定义库
        this.tableData = JSON.parse(JSON.stringify(this.tableDataBack));
        this.logMessage("success", this.$t("CrmCus.text4"));
        this.$parent.$parent.is_open_window_crm_cus = false;
        this.key = "";
        this.checkRadio = "";
        return;
      }
      this.showPage = this.isShowPage;
      this.getList();
    },
    //跳转页面保存
    goPageSave() {
      this.dialogVisible = false;
      if (this.isShowPage == 10) {
        //取消显示自定义库
        this.submitUrl(true);
        return;
      }
      this.submitUrl(false);
      this.showPage = this.isShowPage;
    },
    //点击空白处触发事件
    clickTarget() {
      if (this.showCancel && this.itemIndex) {
        this.cancelLabel();
      }
    },
    //取消编辑标签
    cancelLabel() {
      this.tableData[this.itemIndex - 1].name = this.tableDataLabelBack[
        this.itemIndex - 1
      ].name;
      this.tableData[this.itemIndex - 1].value = this.tableDataLabelBack[
        this.itemIndex - 1
      ].value;
      this.itemIndex = "";
      this.key = "";
      this.checkRadio = "";
      this.showCancel = false;
    },
    //改变标签
    clickTags(index) {
      if (this.itemIndex) {
        if (this.itemIndex - 1 == index) {
          return;
        }
        this.logMessage("error", this.$t("CrmCus.error"));
        return;
      }
      this.showCancel = true;
      this.tableDataLabelBack = JSON.parse(JSON.stringify(this.tableData));
      setTimeout(() => {
        this.key = this.tableData[index].name;
        this.checkRadio = this.colorRadioRe[this.tableData[index].value];
        this.$set(this.tableData[index], "value", "");
        this.itemIndex = index + 1;
      }, 15);
    },
    //增加自定义字段
    addCompany() {
      this.tableData.push({ name: "", value: "" });
    },
    //回调 data{tagsGroup: '', companyGroup:'', starGroup:'', group: ''}
    useCallBack(data, bool) {
      this.logMessage("success", this.$t("layout.success"));
      if (bool) {
        this.$parent.$parent.is_open_window_crm_cus = false;
      } else {
        this.getList();
      }
      // if (window.parent.vue[this.callBack]) {
      //   window.parent.vue[this.callBack](data);
      // }
    },
    //点击标签进行修改
    getCrmCompany(showPage) {
      //检测是否有值发生改变
      let bool = false;
      let ajax = [];
      if (this.showPage == 0) {
        ajax = this.tableData.filter((item) => {
          return item.name != "" && item.value != "";
        });
      } else {
        ajax = this.tableData.filter((item) => {
          return item.name != "";
        });
      }

      if (ajax.length != this.tableDataBack.length) {
        bool = true;
      } else {
        if (this.showPage == 0 && this.showPage == 4) {
          if (ajax.length != 0 && this.tableDataBack.length != 0) {
            this.tableDataBack.forEach((item, index) => {
              //其中有一个有id
              if (
                Object.prototype.hasOwnProperty.call(item, "id") &&
                !Object.prototype.hasOwnProperty.call(ajax[index], "id")
              ) {
                bool = true;
              }
              if (
                !Object.prototype.hasOwnProperty.call(item, "id") &&
                Object.prototype.hasOwnProperty.call(ajax[index], "id")
              ) {
                bool = true;
              }
              if (
                Object.prototype.hasOwnProperty.call(item, "id") &&
                Object.prototype.hasOwnProperty.call(ajax[index], "id")
              ) {
                if (item.id != ajax[index].id) {
                  bool = true;
                }
              }
              if (
                item.name != ajax[index].name ||
                item.value != ajax[index].value
              ) {
                bool = true;
              }
            });
          }
        } else {
          if (ajax.length != 0 && this.tableDataBack.length != 0) {
            //其中有一个有id
            this.tableDataBack.forEach((item, index) => {
              //其中有一个有id
              if (
                Object.prototype.hasOwnProperty.call(item, "id") &&
                !Object.prototype.hasOwnProperty.call(ajax[index], "id")
              ) {
                bool = true;
              }
              if (
                !Object.prototype.hasOwnProperty.call(item, "id") &&
                Object.prototype.hasOwnProperty.call(ajax[index], "id")
              ) {
                bool = true;
              }
              if (
                Object.prototype.hasOwnProperty.call(item, "id") &&
                Object.prototype.hasOwnProperty.call(ajax[index], "id")
              ) {
                if (item.id != ajax[index].id) {
                  bool = true;
                }
              }
              if (
                item.name != ajax[index].name ||
                item.type != ajax[index].type
              ) {
                bool = true;
              }
            });
          }
        }
      }
      if (bool) {
        this.dialogVisible = true;
        this.isShowPage = showPage;
      } else {
        this.showPage = showPage;
        this.getList();
      }
    },
    confirm() {
      if (this.checkRadio == "") {
        this.logMessage("error", this.$t("CrmCus.error1"));
        return;
      }
      if (this.key == "") {
        this.logMessage("error", this.$t("CrmCus.error2"));
        return;
      }
      let bool = false;
      let text = "";
      this.tableData.forEach((item, index) => {
        if (item.name == this.key) {
          if (this.itemIndex) {
            if (this.itemIndex - 1 != index) {
              bool = true;
              text = this.$t("CrmCus.error3");
            }
          } else {
            bool = true;
            text = this.$t("CrmCus.error3");
          }
        }
      });
      if (bool) {
        this.logMessage("error", text);
        return;
      }
      if (this.itemIndex) {
        this.tableData[this.itemIndex - 1].name = this.key;
        this.tableData[this.itemIndex - 1].value = this.colorRadio[
          this.checkRadio
        ];
      } else {
        let obj = {};
        obj["name"] = this.key;
        obj["value"] = this.colorRadio[this.checkRadio];
        this.tableData.push(obj);
      }
      this.itemIndex = "";
      this.key = "";
      this.checkRadio = "";
      this.showCancel = false;
    },
    //提示语
    logMessage(type, message) {
      this.$message({
        type: type,
        message: message,
        showClose: true,
      });
    },
    //删除自定义地段
    delCompany(index) {
      if (Object.prototype.hasOwnProperty.call(this.tableData[index], "id")) {
        let url = "";
        if (this.showPage == 0) {
          //  0任务 1项目 2客户
          if (this.page == 0) {
            url = "/home/CrmTask/delTaskLabel";
          } else if (this.page == 1) {
            url = "/home/CrmTask/delProjectLabel";
          } else {
            url = "/home/CrmContact/delContactLabel";
          }
        } else if (this.showPage == 1) {
          url = "/home/CrmContact/delContactField";
        } else if (this.showPage == 2) {
          url = "/home/CrmContact/delContactStar";
        } else if (this.showPage == 3) {
          url = "/home/CrmContact/delContactGroup";
        } else {
          url = "/home/CrmContact/delContactRegion";
        }
        this.axios
          .post(url, {
            id: this.tableData[index].id,
            page: this.page,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status == 0) {
              this.logMessage("error", res.data.msg);
              return;
            }
            this.tableData.splice(index, 1);
          });
      } else {
        this.tableData.splice(index, 1);
      }
    },
    getList() {
      let url = "";
      if (this.showPage == 0) {
        //  0任务 1项目 2客户
        if (this.page == 0) {
          url = "/home/CrmTask/getTaskLabel";
        } else if (this.page == 1) {
          url = "/home/CrmTask/getProjectLabel";
        } else {
          url = "/home/CrmContact/getContactLabel";
        }
      } else if (this.showPage == 1) {
        url = "/home/CrmContact/getContactField";
      } else if (this.showPage == 2) {
        url = "/home/CrmContact/getContactStar";
      } else if (this.showPage == 3) {
        url = "/home/CrmContact/getContactGroup";
      } else {
        url = "/home/CrmContact/getContactRegion";
      }
      this.loading = true;
      this.axios
        .post(url, {
          page: this.page,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            this.logMessage("error", res.data.msg);
            return;
          }
          this.tableData = res.data.data;
          this.tableDataBack = JSON.parse(JSON.stringify(this.tableData));
        });
    },
    cancel() {
      //检测是否有值发生改变
      let bool = false;
      if (this.tableData.length != this.tableDataBack.length) {
        bool = true;
      } else {
        if (this.showPage == 0 && this.showPage == 4) {
          if (this.tableData.length != 0 && this.tableDataBack.length != 0) {
            this.tableDataBack.forEach((item, index) => {
              //其中有一个有id
              if (
                Object.prototype.hasOwnProperty.call(item, "id") &&
                !Object.prototype.hasOwnProperty.call(
                  this.tableData[index],
                  "id"
                )
              ) {
                bool = true;
              }
              if (
                !Object.prototype.hasOwnProperty.call(item, "id") &&
                Object.prototype.hasOwnProperty.call(
                  this.tableData[index],
                  "id"
                )
              ) {
                bool = true;
              }
              if (
                Object.prototype.hasOwnProperty.call(item, "id") &&
                Object.prototype.hasOwnProperty.call(
                  this.tableData[index],
                  "id"
                )
              ) {
                if (item.id != this.tableData[index].id) {
                  bool = true;
                }
              }
              if (
                item.name != this.tableData[index].name ||
                item.value != this.tableData[index].value
              ) {
                bool = true;
              }
            });
          }
        } else {
          if (this.tableData.length != 0 && this.tableDataBack.length != 0) {
            //其中有一个有id
            this.tableDataBack.forEach((item, index) => {
              //其中有一个有id
              if (
                Object.prototype.hasOwnProperty.call(item, "id") &&
                !Object.prototype.hasOwnProperty.call(
                  this.tableData[index],
                  "id"
                )
              ) {
                bool = true;
              }
              if (
                !Object.prototype.hasOwnProperty.call(item, "id") &&
                Object.prototype.hasOwnProperty.call(
                  this.tableData[index],
                  "id"
                )
              ) {
                bool = true;
              }
              if (
                Object.prototype.hasOwnProperty.call(item, "id") &&
                Object.prototype.hasOwnProperty.call(
                  this.tableData[index],
                  "id"
                )
              ) {
                if (item.id != this.tableData[index].id) {
                  bool = true;
                }
              }
              if (
                item.name != this.tableData[index].name ||
                item.type != this.tableData[index].type
              ) {
                bool = true;
              }
            });
          }
        }
      }
      if (bool) {
        this.dialogVisible = true;
        this.isShowPage = 10;
        // this.$confirm('<tags:lang>页面已修改，是否保存修改</tags:lang>?', '<tags:lang>tips</tags:lang>', {
        //     confirmButtonText: '<tags:lang>保存</tags:lang>',
        //     cancelButtonText: '<tags:lang>cancel</tags:lang>',
        //     type: 'warning'
        // }).then(() => {
        //     this.submitUrl(false)
        //     this.showPage = showPage
        // }).catch(() => { console.log(123) });
      } else {
        this.$parent.$parent.is_open_window_crm_cus = false;
      }
      // this.$confirm('<tags:lang>确定取消吗?</tags:lang>', '<tags:lang>tips</tags:lang>', {
      //     confirmButtonText: '<tags:lang>确定</tags:lang>',
      //     cancelButtonText: '<tags:lang>cancel</tags:lang>',
      //     type: 'warning'
      // }).then(() => {
      //     this.tableData = JSON.parse(JSON.stringify(this.tableDataBack))
      //     this.logMessage('error', '<tags:lang>cancel</tags:lang>')
      //     window.parent.vue.is_open_window_crm_cus = false;
      //     this.key=""
      //     this.checkRadio = ''
      // }).catch(() => { });
    },
    submitUrl(bool) {
      let ajax = {};
      let url = "";
      if (this.showPage == 0) {
        //  0任务 1项目 2客户
        ajax["tagsGroup"] = this.tableData;
        ajax["page"] = this.page;
        if (this.page == 0) {
          url = "/home/CrmTask/putTaskLabel";
        } else if (this.page == 1) {
          url = "/home/CrmTask/putProjectLabel";
        } else {
          url = "/home/CrmContact/putContactLabel";
        }
      } else if (this.showPage == 1) {
        ajax["companyGroup"] = this.tableData.filter((item) => {
          return item.name != "" && item.name != undefined;
        });
        url = "/home/CrmContact/putContactField";
      } else if (this.showPage == 2) {
        ajax["starGroup"] = this.tableData.filter((item) => {
          return item.name != "" && item.name != undefined;
        });
        url = "/home/CrmContact/putContactStar";
      } else if (this.showPage == 3) {
        ajax["group"] = this.tableData.filter((item) => {
          return item.name != "" && item.name != undefined;
        });
        url = "/home/CrmContact/putContactGroup";
      } else {
        ajax["region"] = this.tableData.filter((item) => {
          return item.name != "" && item.name != undefined;
        });
        url = "/home/CrmContact/putContactRegion";
      }
      this.loading = true;
      this.axios.post(url, ajax).then((res) => {
        this.loading = false;
        if (res.data.status != 1) {
          this.logMessage("error", res.data.msg + " " + res.data.data);
          return;
        }
        for (let key in res.data.data) {
          this.tableData = res.data.data[key];
        }
        this.tableDataBack = JSON.parse(JSON.stringify(this.tableData));
        this.useCallBack(res.data.data, bool);
      });
    },
    plugRightShow: function (str) {
      return this.rightShow(str);
    },
    submit() {
      this.$confirm(this.$t("CrmCus.confirmToSave"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.submitUrl(true);
        })
        .catch(() => {});
    },
    pickColor(index) {
      this.checkRadio = index;
    },
  },
  created: function () {
    // let params = this.$route.params;
    // this.callBack = params.callBack;
    // this.page = params.page;
    if (this.page == 2) {
      //判断是否有标签的权限有则展示
      if (this.plugRightShow("CrmContact@getContactLabel")) {
        //有标签的权限
        this.showPage = 0;
      } else if (this.plugRightShow("CrmContact@getContactField")) {
        this.showPage = 1;
      } else if (this.plugRightShow("CrmContact@getContactStar")) {
        this.showPage = 2;
      } else if (this.plugRightShow("CrmContact@getContactGroup")) {
        this.showPage = 3;
      } else if (this.plugRightShow("CrmContact@getContactRegion")) {
        this.showPage = 4;
      }
    }
    this.getList();
  },
};
</script>

<style scoped>
#crm-cus {
  padding-left: 34px !important;
  padding-right: 47px !important;
}
.max-box-tit {
  cursor: pointer;
  color: #a6a6a6;
}
.active-nav {
  color: #ff7315 !important;
  position: relative;
}
.active-nav::after {
  content: " ";
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 2px;
  background: rgba(255, 115, 21, 1);
}
.write-tit {
  color: #a6a6a6;
  cursor: pointer;
  margin-left: 27px;
}
.search-mail {
  margin-top: 43px;
  display: flex;
  margin-bottom: 26px;
}
.search-input {
  width: 732px;
  margin-right: 23px;
}
.infinite-list-wrapper {
  height: calc(100% - 100px);
  margin-top: 40px;
}
.color-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.color-box div {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  cursor: pointer;
}
.color-box div:nth-child(1) {
  background: #0a84ff;
}
.color-box div:nth-child(2) {
  background: #ff575c;
}
.color-box div:nth-child(3) {
  background: #f7be16;
}
.color-box div:nth-child(4) {
  background: #ff7315;
}
.color-box div:nth-child(5) {
  background: #0ace0d;
}
.select-tags {
  float: left;
  padding: 4px 14px;
  color: #fff;
  margin-right: 36px;
  font-size: 14px;
  position: relative;
  border-radius: 3px;
  cursor: move;
  margin-bottom: 5px;
  border: 1px dashed transparent;
}
.select-tags:last-child {
  margin-right: 0;
}
.cus-form_item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-items: center;
  color: #333333;
  font-size: 14px;
  margin-bottom: 12px;
  margin-left: 35px;
}
.form-input {
  width: 280px;
}
.mt16 {
  margin-top: 16px;
}
.mt12 {
  margin-top: 12px;
}
.mt32 {
  margin-top: 32px;
}
.cus-form_tit {
  color: #333333;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 600;
  padding-left: 35px;
}
.el-icon-delete {
  font-size: 16px;
  margin-left: 5px;
}
.btnBox {
  margin-top: 200px;
  display: flex;
  justify-content: flex-end;
}
.cus-tit {
  font-size: 24px;
  color: #1c1c1e;
  margin-top: 23px;
  margin-bottom: 25px;
}
.tit-cus {
  font-size: 14px;
}
.search-input {
  width: 268px;
  margin-right: 33px;
}
.tag-tit {
  color: #1c1c1e;
  font-size: 18px;
  margin-right: 13px;
  margin-bottom: 16px;
}
.tag-tit .num {
  color: #858585;
  font-size: 16px;
  margin-left: 13px;
}
.tag-tit .unit {
  color: #858585;
  opacity: 0.6;
  font-size: 12px;
}
.del-icon {
  font-size: 10px;
  position: absolute;
  right: 2px;
  top: 2px;
  cursor: pointer;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.handle {
  cursor: move;
}
.active-color {
  box-shadow: 0px 2px 4px 0px rgba(10, 148, 255, 1);
  border: 1px solid rgba(255, 255, 255, 1);
}
.ml76 {
  margin-left: 76px !important;
}
.cancel-btn {
  background: rgba(255, 115, 21, 0.1);
  border-color: rgba(255, 115, 21, 0.1);
  color: #ff7315;
}
.cancel-btn:hover {
  color: #ff7315;
  border-color: rgba(255, 115, 21, 0.1);
  background-color: rgba(255, 115, 21, 0.1);
}
.border-tag {
  color: #1c1c1e;
  border: 1px dashed #aaa;
}
.add-top_box,
.add-w {
  width: 608px;
  margin-bottom: 8px;
}
.add-top_box span {
  display: inline-block;
  width: 50%;
}
.add-form_item {
  margin: 20px 0;
  padding: 5px 10px;
  border: 1px dashed transparent;
  display: flex;
  align-items: center;
  cursor: move;
}
.add-form_item:hover {
  border: 1px dashed #aaa;
}
.add-form_item:hover .tip-popover {
  visibility: visible;
}
.add-draggable-item {
}
.tip-popover {
  padding: 5px;
  min-width: 10px;
  visibility: hidden;
}
.form-user {
  padding-left: 35px;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}
.no-save_btn {
  height: 36px;
  position: absolute;
  left: 5px;
  top: 2px;
  color: #333;
  font-size: 22px;
}
.w608 {
  width: 608px;
}
.el-dialog__body {
  min-height: 55px;
  text-align: center !important;
}
.cus-item_container {
  /* padding-left: 35px; */
}
</style>