<template>
  <div v-show="is_open" class="drawer-box">
    <div class="black-bg" @click="showDrawer"></div>
    <div :class="[is_open ? 'showDetail' : '', 'email-detail-box']">
      <!-- <iframe
        v-show="is_open"
        :src="nowPath"
        name="crmCusIframe"
        frameborder="0"
        scrolling="yes"
        height="100%"
        width="100%"
        class="zc-email-window"
      ></iframe> -->
      <crm-cus :page="page" :callback="call_back" :is_open="is_open" />
    </div>
  </div>
</template>

<script>
import CrmCus from "@/views/CrmCus.vue";
export default {
  components: {
    CrmCus,
  },
  props: ["is_open", "open_path", "page", "call_back"],

  data: function () {
    return {
      new_path: "",
      nowPath: "",
      isFirst: true,
    };
  },
  // watch: {
  //   is_open() {
  //     if (this.isFirst) {
  //       this.new_path += "/page/" + this.page;
  //       this.nowPath = this.new_path;
  //       this.isFirst = false;
  //     }
  //   },
  // },
  methods: {
    showDrawer() {
      if (this.is_open) {
        this.is_open = false;
        this.$parent.is_open_window_crm_cus = false;
      }
    },
  },
  created: function () {
    let newPath = "/home/Plugs/crmCus";
    if (this.open_path.length > 0) {
      newPath = "/" + this.open_path + newPath;
    }
    newPath += "/callback/" + this.call_back;
    this.new_path = newPath;
  },
};
</script>